<svelte:options customElement={{
    tag: 'hh-recs',
    shadow: 'none',
    extend: (customElementConstructor) => {
			if (!customElements.get('hh-recs')) {
				return class extends customElementConstructor {};
			}
		}
}}/>

<script>
    import ProductRecs from "./ProductRecs.svelte"    
	export let pageType='', productCode='', cardStyle='', title='', component='', categories='';
    if(!pageType) pageType = document.querySelector('html')?.dataset?.pageType || ''
    let childProps = {
        pageType,
        productCode,
        categories,
        cardStyle,
        title,
        component
    }
</script>
<slot>
    <ProductRecs {...childProps} />
</slot>