<script>
    import ProductCard from "@home-ecommerce/hh-svelte-productcard"
    import ShelfControl from "./ShelfControl.svelte";

    // export let type='', product='', category='', cardStyle='', title='';
    export let pageType='', productCode='', cardStyle='', title='', component='', categories='';
    const {CategoryLevel2='', CategoryLevel3='', CategoryLevel4='', CategoryLevel5=''} = categories ? JSON.parse(categories): {}

    let lang = document.querySelector('html').lang
    // console.log(`Custom component connected!`, pageType, productCode, cardStyle, title, component)
    let recProducts = []
    let loading = true;

    let preferredStore = localStorage.preferredStore ? parseInt( JSON.parse(localStorage.preferredStore)?.code || localStorage.preferredStore ) : false
    let preferredStoreAttr = localStorage.preferredStoreAttr ? JSON.parse(localStorage.preferredStoreAttr) || false : false  
    let preferredWarehouse = parseInt(preferredStoreAttr?.tenantWarehouse) || 1

    if(!window.getCookie) window.getCookie = getCookie
    let monetateId = window.getCookie('mt.v') || false
    let recTokens;
    let isErrored = false
    let isRendered = false
    let cartLines = false

    function getCookie(name) {
        let cookieArr = document.cookie.split(";");
        for (let i = 0; i < cookieArr.length; i++) {
            let cookiePair = cookieArr[i].split("=");
            if (name == cookiePair[0].trim()) return decodeURIComponent(cookiePair[1]);
        }
        return null;
    }

    function removeDuplicates(array, key) {
        const tempArray = [];
        const ids = {};
        for (let i = 0; i < array.length; i++) {
            if (!ids[array[i][key]]) {
                ids[array[i][key]] = true;
                tempArray.push(array[i]);
            }
        }
        return tempArray;
    }


    const fetchProducts = async(productArr)=> {
        try {
            if(!preferredStore) throw 'No preferred Store';
            if (!preferredWarehouse) throw "no preferred warehouse"
            const productCodes = productArr.map(item => item.productCode)
            recTokens = productArr.map(item =>item.recToken).filter(Boolean)
            if (!productCodes || !productCodes.length) throw "product codes array is empty"
            const url = `/_data/${lang}/product/bulk-product-data/${productCodes.join(',')}/${preferredStore},${preferredWarehouse}?buyability=false`
            const response = await fetch(url)
            const data = await response.json()
            recProducts = data?.items?.length ? data.items : false
    
            /** Add recToken field to the product*/
            recProducts = recProducts.map(item => {
                return {
                    ...item,
                    recToken: productArr.find(product => item.productCode == product.productCode )?.recToken
                }
            })
            if(recProducts.length) fetch(`https://monetate-recs-proxy.homehardware.dev/?&monetateId=${monetateId}&recImpressions=${recTokens.join(',')}`)
            
            return	
        } catch (error) {
            console.warn('fetchProducts', error)
            recProducts = []
            isErrored = true
            return
        }
    }

    const main= async()=> {
        try {
            // let response = await fetch('https://monetate-recs-proxy.homehardware.dev')
            if(isRendered) return
            let response
            switch (pageType) {
                case 'cart':
                    if(!cartLines) return;
                    response = await fetch(`https://monetate-recs-proxy.homehardware.dev/?storeCode=${preferredStore}&pageType=${pageType}&monetateId=${monetateId}&pageUrl=${encodeURI(window.location.origin + window.location.pathname)}`, {
                        method: 'POST',
                        body: JSON.stringify({cartLines})
                    })
                    break;
                default:
                    response = await fetch(`https://monetate-recs-proxy.homehardware.dev/?storeCode=${preferredStore}&productCode=${productCode}&CategoryLevel2=${CategoryLevel2}&CategoryLevel3=${CategoryLevel3}&CategoryLevel4=${CategoryLevel4}&CategoryLevel5=${CategoryLevel5}&pageType=${pageType}&monetateId=${monetateId}&pageUrl=`+encodeURI(window.location.origin + window.location.pathname))
                    break;
            }
            response = await response.json()
            // console.log(response)
            const actionObj = response?.data?.responses?.[0]?.actions?.filter(a => a.component === component ) || false
            let productCrosssellList = document.querySelector('#hh-mfe-pdp-buy')?.dataset?.tenantProductCrosssell
            if (!actionObj.length) {
                // alert('no actionObj');
                throw 'no actionObj'
            }
            let productsArr = []

            if( productCrosssellList  && component == 'relatedProducts') {
                let codes = productCrosssellList.split(',');
                let regex = /^\d{7}$/;
                let allCodesValid = codes.every(code => regex.test(code));
                
                if(allCodesValid) {
                    codes.forEach(code => {
                        productsArr.push({
                            id: code,
                            recToken: ''
                        })
                    })
                }
                
            }
            actionObj.forEach(action =>{
                productsArr.push(...action.items)
            })

            if(!productsArr.length) throw `No products available for monetate ${component}`

            productsArr = productsArr.map(item => {
                return JSON.stringify({
                    productCode: item.id,
                    recToken: item.recToken
                })
            })

            productsArr = Array.from(new Set(productsArr)).map(JSON.parse)

            productsArr = removeDuplicates(productsArr, 'productCode')

            productsArr = productsArr.slice(0, 12) //Set it to 12
            await fetchProducts(productsArr)
            loading= false
            isRendered = true
        } catch (error) {
            loading= false
            isErrored = true
            console.warn(error)
        }
    }

     function inViewport(node) {
        let observerConfig = {
            root: null,
            rootMargin: "40%", 
            threshold: .5
	    };
        const observer = new IntersectionObserver(async([entry]) => {
            if (entry.intersectionRatio > 0) {
                // console.log('Element entered the viewport')
                await main()
                observer.unobserve(node)
            }
        }, observerConfig);

        observer.observe(node);

        return {
            destroy() {
                observer.disconnect()
            }
        };
    }

    window.addEventListener('monetaterecs', async function(e) {
        // console.log('From monetateRecs event:', e)
        cartLines = e?.detail?.cartLines || false
        main()  
    })
   
    // main()
</script>

<div use:inViewport data-decoupled>
    {#if loading}
        {#if cardStyle == "vertical"}
            <section class="Product Card Column">
                {#each Array(4) as item, i}
                    <ProductCard isSkeleton={true} />
                {/each}
            </section>
        {:else}
        <ShelfControl>
            {#each [1,2,3,4,5,6,7,8,9,10] as item, i}
                <ProductCard isSkeleton={true} />
            {/each}
        </ShelfControl>
        {/if}
    {:else if !isErrored}
        {#if cardStyle == "vertical"}
            <h3>{title}</h3>
            <section class="Product Grid Card Column">
                {#each recProducts as item}
                    <ProductCard
                        lang = {lang}
                        productCode={item.productCode}
                        propertiesObj={item.properties || {}}
                        contentObj={item.content || {}}
                        priceObj={item.price || {}}
                        buyabilityObj={false}
                        hasWishlist={false}
                        hasAddToCart={false}
                        hasCompare={false}
                        recToken={item.recToken || false}
                    />
                {/each}
            </section>
        {:else}
            <!-- else content here -->
            <ShelfControl title={title} >
                {#each recProducts as item}
                    <ProductCard
                        lang = {lang}
                        productCode={item.productCode}
                        propertiesObj={item.properties || {}}
                        contentObj={item.content || {}}
                        priceObj={item.price || {}}
                        buyabilityObj={false}
                        hasWishlist={true}
                        hasAddToCart={false}
                        hasCompare={false}
                        recToken={item.recToken || false}
                    />
                {/each}
            </ShelfControl>
        {/if}
    {/if}
</div>

