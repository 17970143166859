<script>
	
	import { onMount } from 'svelte'

	let shelf
	let children = []
	let columns = getComputedStyle(document.documentElement).getPropertyValue('--grid-column')
	let page = 1
	let pages = 1
	let prev = 1
	let next = 1
	let first = false
	let last = false
	let jumpers = []
	export let title=false
	
	onMount(async () => {
		children = shelf ? shelf.children : []
		shelfResize()
	})
	
	function shelfResize() {
		columns = shelf ? getComputedStyle(shelf).getPropertyValue('--grid-column') : getComputedStyle(document.documentElement).getPropertyValue('--grid-column')
	}

	function scrollToId(id) {
		children[id].scrollIntoView({behavior: "smooth", block: "nearest", inline: "start"})
		return
	}

	function shelfScroll(e) {
		// based on scroll position, determine which page we are on
		let scrollLeft = e.target.scrollLeft
		let scrollWidth = e.target.scrollWidth
		let clientWidth = e.target.clientWidth
        //Subtracting scrollWidth with 100px value so as to fix pageNumber bug when scrolling for 11-12 productCards.
		if (scrollLeft + clientWidth < scrollWidth - 100) {
			let n = Math.round(scrollLeft / (scrollWidth / children.length))
			page = Math.round(n/columns) + 1
		} else {
			page = pages
			// console.log('last page')
		}
	}

	$: {
		// calculate how many pages there should be
		pages = Math.ceil(children.length / columns)
		// based on the number of columns, build an array of jump points
		jumpers = Object.keys(children).filter((value, index, arr) => {
			return index % columns == 0
		})
		// based on current page, determine the index for the previous & next jumpers
		prev = jumpers[ page==1 ? 0 : page-2 ]
		next = jumpers[ page==pages ? page-1 : page ]
		first = page==1
		last = page==pages
	}

</script>


<svelte:window on:resize={shelfResize}/>








<div class="recs-head">
	<h2 class="recs-title">{title ? title : ''}</h2>
	<h3 class="ShelfController">
		<button type="button" on:click={scrollToId(prev)} disabled={first}>&larr;</button>
		<b>{page}/{pages}</b>
		<button type="button" on:click={scrollToId(next)} disabled={last}>&rarr;</button>
	</h3>
</div>
<div class="Grid Shelf" bind:this={shelf} on:scroll={shelfScroll} style="--children:{children.length}">
	<slot></slot>
</div>